const typesAlert = [
  {
    identifier: 'alert',
    description: 'Alerta',
  },
  {
    identifier: 'email',
    description: 'E-mail',
  },
]
export default typesAlert
