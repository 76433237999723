<template>
  <b-container>
    <b-row class="align-items-center">
      <b-col md="3">
        <b-form-checkbox
          v-model="model.is_delete_stations_inventory"
          name="is_delete_stations_inventory"
          switch
          inline
          @change="changedStationsInventoryDelete"
        >
          Apagar estações e inventário
        </b-form-checkbox>
      </b-col>
      <b-col md="3">
        <b-form-group
          class="mt-1"
          label="Sem comunicação a"
          label-for="without_comunication"
        >
          <b-input-group append="dia(s)">
            <b-form-input
              id="without_comunication"
              v-model="model.without_comunication"
              :disabled="!model.is_delete_stations_inventory"
              :readonly="!model.is_delete_stations_inventory"
              placeholder="dia(s)"
              type="number"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-checkbox
          v-model="model.detect_software_components"
          name="detect_software_components"
          switch
          inline
        >
          Detectar componentes de software adicionais
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <b-form-group
          label="Inventário de Sofware a cada"
          label-for="software_inventory"
        >
          <b-input-group append="minuto(s)">
            <b-form-input
              id="software_inventory"
              v-model="model.software_inventory"
              placeholder="minuto(s)"
              type="number"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Inventário de Hardware a cada"
          label-for="hardware_inventory"
        >
          <b-input-group append="hora(s)">
            <b-form-input
              id="hardware_inventory"
              v-model="model.hardware_inventory"
              placeholder="hora(s)"
              type="number"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Descartar alertas de disco com menos de"
          label-for="dismiss_disk_alerts"
        >
          <b-input-group append="GB's">
            <b-form-input
              id="dismiss_disk_alerts"
              v-model="model.dismiss_disk_alerts"
              placeholder="GB's"
              type="number"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Alerta para utilização de disco"
          label-for="disk_usage_alert"
        >
          <b-input-group append="%">
            <b-form-input
              id="disk_usage_alert"
              v-model="model.disk_usage_alert"
              placeholder="%"
              type="number"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <b-form-group
          label="Alerta para expiração da garantia em"
          label-for="warranty_expiration_alerts"
        >
          <b-input-group append="dia(s)">
            <b-form-input
              id="warranty_expiration_alerts"
              v-model="model.warranty_expiration_alerts"
              placeholder="dia(s)"
              type="number"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

  </b-container>
</template>
<script>

export default {
  name: 'InventoryGeneral',
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      model: {
        is_delete_stations_inventory: false,
        without_comunication: '0',
        hardware_inventory: '',
        software_inventory: '',
        disk_usage_alert: '',
        dismiss_disk_alerts: '',
        detect_software_components: '',
        warranty_expiration_alerts: '',
      },
    }
  },
  watch: {
    data(value) {
      this.model = value
    },
    model: {
      handler() {
        this.$emit('onChangeGeneralTab', this.model)
      },
      deep: true,
    },
  },
  mounted() {
  },
  methods: {
    changedStationsInventoryDelete() {
      this.model.without_comunication = 0
      if (this.model.is_delete_stations_inventory) {
        this.model.without_comunication = 30
      }
    },
  },
}
</script>

<style scoped>

</style>
