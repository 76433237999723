const itemsAlert = [
  {
    identifier: 'software_changes',
    description: 'Alterações de Software',
  },
  {
    identifier: 'hardware_changes',
    description: 'Alterações de Hardware',
  },
  {
    identifier: 'excess_licenses',
    description: 'Excesso de Licenças',
  },
  {
    identifier: 'usb_devices',
    description: 'Dispositivo USB',
  },
  {
    identifier: 'disk_space',
    description: 'Espaço em Disco',
  },
  {
    identifier: 'warranty',
    description: 'Garantia',
  },
]
export default itemsAlert
