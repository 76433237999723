const itemsHardware = [
  {
    identifier: 'processor',
    description: 'Processador',
  },
  {
    identifier: 'mother_board',
    description: 'Placa Mãe',
  },
  {
    identifier: 'memory',
    description: 'Memória',
  },
  {
    identifier: 'hard_drive',
    description: 'Dísco Rígido',
  },
  {
    identifier: 'cd_dvd',
    description: 'CD / DVD',
  },
  {
    identifier: 'removable_disk',
    description: 'Disco Removível',
  },
  {
    identifier: 'video_card',
    description: 'Placa de Vídeo',
  },
  {
    identifier: 'network_card',
    description: 'Placa de Rede',
  },
  {
    identifier: 'sound_card',
    description: 'Placa de Som',
  },
  {
    identifier: 'modem',
    description: 'Modem',
  },
  {
    identifier: 'mouse',
    description: 'Mouse',
  },
  {
    identifier: 'keyboard',
    description: 'Teclado',
  },
  {
    identifier: 'partition',
    description: 'Partição',
  },
]
export default itemsHardware
