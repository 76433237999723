<template>
  <b-container>
    <Grid
      :fields="itemsHeader"
      :items="itemsDescription"
      edit-button-label="edit"
      new-button-label="new"
      :show-search="false"
      :show-pagination="false"
    >
      <template #cell(alert)="data">
        <b-form-checkbox
          v-model="modelAlert[data.item.identifier]"
          :name="'alert_'+data.item.identifier"
          switch
          inline
          @change="changed"
        />
      </template>
      <template #cell(email)="data">
        <b-form-checkbox
          v-model="modelEmail[data.item.identifier]"
          :name="'email_'+data.item.identifier"
          switch
          inline
          @change="changed"
        />
      </template>
    </Grid>
  </b-container>
</template>
<script>

import itemsAlert from '@/variables/settings/itemsAlert'
import typesAlert from '@/variables/settings/typesAlert'

export default {
  name: 'InventoryAlerts',
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      itemsDescription: itemsAlert,
      itemsHeader: [
        {
          key: 'description',
          label: 'Descrição',
          class: 'w-30',
        },
      ],
      modelAlert: {
      },
      modelEmail: {
      },
    }
  },
  watch: {
    data(value) {
      this.modelAlert = value.alert
      this.modelEmail = value.email
    },
  },
  mounted() {
    this.startModel()
    this.setGridHeaders()
  },
  methods: {
    startModel() {
      this.itemsDescription.forEach(item => {
        this.modelAlert[item.identifier] = false
        this.modelEmail[item.identifier] = false
      })
    },
    setGridHeaders() {
      typesAlert.forEach(type => {
        this.itemsHeader.push({
          key: type.identifier,
          label: type.description,
          class: 'w-5',
        })
      })
      /* header para deixar espaço em branco a direita */
      this.itemsHeader.push({
        key: 'empty',
        label: '',
        class: 'w-70',
      })
    },
    changed() {
      const emitValue = {
        alert: this.modelAlert,
        email: this.modelEmail,
      }
      this.$emit('onChangeAlertsTab', emitValue)
    },
  },
}
</script>

<style scoped>

</style>
