<template>
  <b-container>
    <page-header name="Configurações de Inventário" />
    <b-card>
      <b-tabs>
        <!-- ABA GERAL -->
        <b-tab :active="isActive('general')" @click="changeActiveTab('general')">
          <template #title>
            <feather-icon icon="HomeIcon" />
            <span>Geral</span>
          </template>
          <inventory-general :data="model.general" @onChangeGeneralTab="setModelGeneralValue" />
        </b-tab>
        <b-tab :active="isActive('alerts')" @click="changeActiveTab('alerts')">
          <template #title>
            <feather-icon icon="MailIcon" />
            <span>Alertas</span>
          </template>
          <inventory-alerts :data="model.alerts" @onChangeAlertsTab="setModelAlertsValue" />
        </b-tab>
        <b-tab :active="isActive('hardware')" @click="changeActiveTab('hardware')">
          <template #title>
            <feather-icon icon="HardDriveIcon" />
            <span>Hardware</span>
          </template>
          <inventory-hardware-items :data="model.hardware_items" @onChangeHardwareItemsTab="setModelHardwareItemsValue" />
        </b-tab>
      </b-tabs>

      <div class="text-right">
        <i2-icon-button
          variant="primary"
          icon="SaveIcon"
          @click="save()"
        >
          Salvar
        </i2-icon-button>
      </div>
    </b-card>
  </b-container>
</template>

<script>

import InventoryGeneral from '@/views/settings/inventory/InventoryGeneral.vue'
import InventoryAlerts from '@/views/settings/inventory/InventoryAlerts.vue'
import InventoryHardwareItems from '@/views/settings/inventory/InventoryHardwareItems.vue'

export default {
  name: 'InventorySettings',
  components: { InventoryHardwareItems, InventoryAlerts, InventoryGeneral },
  data() {
    return {
      activeTab: 'general',
      model: {
        general: {},
        alerts: {},
        hardware_items: {},
      },
    }
  },
  async mounted() {
    await this.getConfiguration()
  },
  methods: {
    async getConfiguration() {
      const response = await this.$http.get('inventory-settings/client')
      this.model.general = response.general
      this.model.alerts = response.alerts
      this.model.hardware_items = response.hardware_items
    },
    async save() {
      const response = await this.$http.post('inventory-settings', this.model)
      if (response.error_message) {
        this.$notify.error(response.error_message)
        return false
      }
      this.$notify.success('Configuração salva com sucesso.')
      return true
    },
    isActive(tab) {
      return this.activeTab === tab
    },
    changeActiveTab(tab) {
      this.activeTab = tab
    },
    setModelValue(prop, value) {
      this.model[prop] = value
    },
    setModelGeneralValue(value) {
      this.setModelValue('general', value)
    },
    setModelAlertsValue(value) {
      this.setModelValue('alerts', value)
    },
    setModelHardwareItemsValue(value) {
      this.setModelValue('hardware_items', value)
    },
  },
}
</script>

<style scoped>

</style>
