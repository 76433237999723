<template>
  <b-container>
    <b-row>
      <b-col md="6">
        <Grid
          :fields="columns"
          :items="itemsGridOne"
          edit-button-label="edit"
          new-button-label="new"
          :show-search="false"
          :show-pagination="false"
          :show-total-rows="false"
        >
          <template #cell(action)="data">
            <b-form-checkbox
              v-model="model[data.item.identifier]"
              :name="data.item.identifier"
              switch
              inline
              @change="changed"
            />
          </template>
        </Grid>
      </b-col>
      <b-col md="6">
        <Grid
          :fields="columns"
          :items="itemsGridTwo"
          edit-button-label="edit"
          new-button-label="new"
          :show-search="false"
          :show-pagination="false"
          :show-total-rows="false"
        >
          <template #cell(action)="data">
            <b-form-checkbox
              v-model="model[data.item.identifier]"
              :name="data.item.identifier"
              switch
              inline
              @change="changed"
            />
          </template>
        </Grid>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

// onChangeHardwareItemsTab
import itemsHardware from '@/variables/settings/itemsHardware'

export default {
  name: 'InventoryHardwareItems',
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      columns: [
        {
          key: 'description',
          label: 'Descrição',
          class: 'w-60',
        },
        {
          key: 'action',
          label: 'Ativo',
          class: 'w-40',
        },
      ],
      itemsGridOne: [],
      itemsGridTwo: [],
      model: {},
    }
  },
  watch: {
    data(value) {
      this.model = value
    },
  },
  mounted() {
    this.start()
  },
  methods: {
    changed() {
      this.$emit('onChangeHardwareItemsTab', this.model)
    },
    start() {
      const totalColumnOne = Number((itemsHardware.length / 2)).toPrecision(1)
      let index = 1
      itemsHardware.forEach(item => {
        this.model[item.identifier] = false
        if (index <= totalColumnOne) {
          this.itemsGridOne.push(item)
        }
        if (index > totalColumnOne) {
          this.itemsGridTwo.push(item)
        }
        index += 1
      })
    },
  },
}
</script>

<style scoped>

</style>
